<template>
	<div class="w-full h-full grid grid-cols-2 text-lgvw" v-if="currentShop">
		<div class="col-span-1 p-5 h-full">
			<p class="w-full text-center text-warning">以下輪候叫號</p>
			<div class="grid grid-flow-col grid-cols-2 grid-rows-2 h-max gap-10">
				<div v-for="type in currentShop.queueTypes" :key="type.prefix" class="col-span-1">
					<button class="bg-secondary w-full h-full rounded-3xl text-white" :class="alertQueue && type.prefix === currentPrefix ? 'animate-pulse bg-warning' : null">
						<p class="text-smvw" :class="alertQueue && type.prefix === currentPrefix ? 'text-white' : 'text-warning'">{{ type.minPax }}{{ type.maxPax === 0 ? '+' : `-${type.maxPax}` }}人</p>
						<p v-if="$store.getters.showNewDay">--</p>
						<p v-if="!$store.getters.showNewDay">{{ getQueue(type.prefix) }}</p>
					</button>
				</div>
			</div>
		</div>

		<div class="col-span-1 p-5 h-full w-full text-center relative bg-secondary" :class="alertPackedSaleOrders ? 'animate-pulse bg-primary' : null">
			<p class="w-full text-center pb-5" :class="alertPackedSaleOrders ? 'text-white' : 'text-primary'">以下號碼可取餐</p>
			<div class="grid grid-cols-3 overflow-hidden w-full h-max gap-5">
				<div v-for="packedSaleOrder in packedSaleOrders" :key="packedSaleOrder.value" class="col-span-1">
					<button class="w-full h-full rounded-3xl text-secondary bg-light-gray p-2 text-smvw">S{{ packedSaleOrder.referenceId.slice(-3) }}</button>
				</div>
			</div>
		</div>
		<audio class="display-none" id="myAudio" preload="auto" allow="autoplay">
			<source src="@/assets/audios/pushSound.ogg" type="audio/ogg" />
			<source src="@/assets/audios/pushSound.mp3" />
		</audio>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			currentOnCall: [],
			currentPrefix: null,
			alertQueue: false,
			alertPackedSaleOrders: false,
		}
	},

	computed: {
		...mapGetters(['currentShop', 'queues', 'saleOrders']),

		onCallQueues() {
			const result = []
			this.queues.forEach((queue) => {
				if (queue.status === 'CALL') return result.push(queue)
			})
			return result
		},

		packedSaleOrders() {
			const result = []
			this.saleOrders.forEach((order) => {
				if (order.method === 'DINEIN') return
				else if (order.status === 'CALLED') return result.push(order)
			})
			return result
		},
	},

	watch: {
		onCallQueues: function (newValue, oldValue) {
			const newQueue = newValue.filter((x) => !oldValue.includes(x))[0]
			if (newQueue) {
				this.currentOnCall.unshift(newQueue)
				this.currentPrefix = newQueue.prefix
				this.alertQueue = true
				this.playAudio()
				setTimeout(() => {
					this.currentOnCall.splice(100, 1)
					this.currentPrefix = null
					this.alertQueue = false
				}, 5000)
			}
		},

		packedSaleOrders: function (newValue, oldValue) {
			if (newValue === []) return
			const newSaleOrder = newValue.filter((x) => !oldValue.includes(x))[0]
			if (newSaleOrder) {
				this.alertPackedSaleOrders = true
				this.playAudio()
				setTimeout(() => {
					this.alertPackedSaleOrders = false
				}, 5000)
			}
		},
	},

	methods: {
		getQueue(prefix) {
			const queue = this.currentOnCall.find((queue) => queue.prefix === prefix)
			if (!queue) return '--'
			return queue.referenceId
		},

		playAudio() {
			let audio = document.getElementById('myAudio')
			audio.muted = true
			audio.pause()
			audio.currentTime = 0
			audio.muted = false
			audio.play()
			setTimeout(() => {
				audio.pause()
			}, 3000)
		},
	},
}
</script>
